
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter, Mutation, State} from "vuex-class";
import {ROUTES} from "@/data";
import {IAgencyDB, INotificationDB, IUserDB, StoreAction, StoreMutation} from "@/types";
import {showModal} from "@/helpers/callables";
import RenameView from "@/components/modal/RenameView.vue";
import RemoveView from "@/components/modal/RemoveView.vue";
import LayoutTooltip from "@/components/layoutTooltips/LayoutTooltip.vue";
import LeftBar from "@/components/ats/layout/LeftBar.vue";
import TopBar from "@/components/ats/layout/TopBar.vue";

@Component({
    name: "Layout",
    components: {TopBar, LeftBar, LayoutTooltip, RemoveView, RenameView}
})
export default class Layout extends Vue {
    loading = false;
    selectedView: any = null;
    notificationLoading = false;
    title: string = "";
    viewToChange:any =   "";
    showRenameView = false;
    showRemoveView = false;
    viewLoading = false;

    @State('menuClosed') menuClosed!: boolean;
    @State('configuration') configuration: any;
    @State('currentUser') currentUser!: IUserDB;
    @State('notifications') notifications!: INotificationDB[];
    @State('currentAgency') currentAgency!: IAgencyDB;
    @State('selectedCollaborators') selectedCollaborators!: IUserDB[];

    @Action('actions/getGlobalConfiguration') getGlobalConfiguration!: StoreAction;
    @Action('actions/getProfile') getProfile!: StoreAction;
    @Action('actions/getUserNotifications') getUserNotifications!: StoreAction;
    @Action('actions/getAgencyCollaborators') getAgencyCollaborators!: StoreAction;
    @Action('actions/loadAgency') loadAgency!: StoreAction;
    @Action('actions/loadCompany') loadCompany!: StoreAction;
    @Action('actions/createPreconfiguredView') createPreconfiguredView!: StoreAction;
    @Action('actions/removePreconfiguredView') removePreconfiguredView!: StoreAction;
    @Action('actions/editPreconfiguredView') editPreconfiguredView!: StoreAction;

    @Mutation('setUser') setUser!: StoreMutation;
    @Mutation('setAgency') setAgency!: StoreMutation;
    @Mutation('setCompany') setCompany!: StoreMutation;
    @Mutation('setLinks') setLinks!: StoreMutation;
    @Mutation('setCollaborators') setCollaborators!: StoreMutation;
    @Mutation('setSelectedView') setSelectedView!: StoreMutation;
    @Mutation('setSelectedCollaborators') setSelectedCollaborators!: StoreMutation;
    @Mutation('setLastSelectedView') setLastSelectedView!: StoreMutation;
    @Mutation('toggleMenuClose') toggleMenuClose!: StoreMutation;
    @Getter('isCompany') isCompany!: any;
    @Getter('isAgency') isAgency!: any;
    @Getter('isSupervisor') isSupervisor!: any;
    @State('supervisorAgencyId') supervisorAgencyId!: any;
    @Getter('hasATS') hasATS!: boolean;

    @Getter('allUserViews') allUserViews!: any;

    @Watch('selectedCollaborators', {deep: true})
    onSelectedCollaborators() {
        this.getUserNotifications();
    }


    onTitle(title: string) {
        this.title = title;
    }

    checkRoles(reloading?:boolean) {
        let isSupervisorRoute = this.$route.name === ROUTES.ATS.SUPERVISOR_DASHBOARD
            || this.$route.name == ROUTES.ATS.SUPERVISOR_STATISTICS

        if (this.isSupervisor) {
            if (!this.supervisorAgencyId && !isSupervisorRoute) {
                this.$router.push({name: ROUTES.ATS.SUPERVISOR_DASHBOARD});
                setTimeout(() => {
                    this.loading = false;
                }, 1000);
                return;
            }

            if (this.supervisorAgencyId && reloading) {
                this.$router.push({name: ROUTES.APP.FOLLOWING});
            }
        } else if (isSupervisorRoute) {
            this.$router.push({name: ROUTES.ATS.FOLLOWING._ROOT});
        }

        if (!this.hasATS && !this.isSupervisor) {
            this.$router.push({name: ROUTES.APP.HOME});
        }
    }

    renameView(view: any) {
        // rename
        this.viewToChange = view;
        this.$set(view, 'opened',false);
        this.displayRenameView();
    }

    removeView(view: any) {
        // rename
        this.viewToChange = view;
        this.$set(view, 'opened',false);
        this.displayRemoveView();
    }


    displayRenameView() {
        showModal.call(this,'RenameView');
    }

    displayRemoveView() {
        showModal.call(this,'RemoveView');
    }

    async selectFirstView() {
        if (this.configuration.views
            && this.configuration.views[<string>this.$route.name]
            && this.configuration.views[<string>this.$route.name].preconfiguredViews
            && this.configuration.views[<string>this.$route.name].preconfiguredViews[0]) {
            // @ts-ignore;
            let view = this.configuration.views[<string>this.$route.name].preconfiguredViews[0];
            await this.selectView(view);
            await this.setLastSelectedView({route: this.$route.name, view});
        }
    }

    selectView(view: any) {
        if (this.$route.query?.mode !== view.nameSlug) {
            this.$router.push({name: this.$route.name || '', query: {mode: view.nameSlug}})
        }
        this.setLastSelectedView({route: this.$route.name, view});
        this.selectedView = view;
    }

    getViews(context: string) {
        return this.allUserViews(context).map((i:any) => {
            i.opened = false;
            return i;
        });
    }

    async submitRenameView(data: any) {
        try {
            await this.setUser(await this.editPreconfiguredView({
                nameSlug: this.viewToChange.nameSlug,
                data: {
                    ...this.viewToChange,
                    name: data.modelName
                }
            }));
            this.viewToChange.label = data.modelName;
            await this.setSelectedView(this.viewToChange);
            await this.setLastSelectedView({route: this.$route.name, view: this.viewToChange});
            this.selectView(this.viewToChange);
            this.$modal.hide('RenameView');
            this.viewToChange = null;
        } catch (e) {
            console.log(e);
        }
    }

    async submitRemoveView() {
        try {
            await this.setUser(await this.removePreconfiguredView({
                nameSlug: this.viewToChange.nameSlug
            }));
            this.$modal.hide('RemoveView');
            await this.selectFirstView();
            this.viewToChange = null;
        } catch (e) {
            console.log(e);
        }
    }

    async registerView(data:any) {
        this.viewLoading = true;
        try {
            await this.setUser(await this.createPreconfiguredView({
                viewSection: this.$route.name,
                name: data.modelName,
                filters: data.filters,
                view: data.view,
                mode: data.mode
            }));
            this.$modal.hide('SaveView');
            // @ts-ignore;
            let view:any = this.currentUser.configuration.views[this.$route.name].find(v => v.name === data.modelName);
            await this.setSelectedView(view);
            await this.setLastSelectedView({route: this.$route.name, view});
            this.selectView(view);
            this.viewToChange = null;
        } catch (e) {
            console.log(e);
        }
        this.viewLoading = false;
    }

    async eraseView(data: any) {
        this.viewLoading = true;
        try {
            await this.setUser(await this.editPreconfiguredView({
                nameSlug: data.nameSlug,
                data: {
                    ...data.selectedView,
                    filters: data.filters,
                    mode: data.mode
                }
            }));
            this.$modal.hide('SaveView');
            this.viewToChange = null;
        } catch (e) {
            console.log(e);
        }
        this.viewLoading = false;
    }

    async init(reloading?: boolean) {
        this.loading = true;
        try {
            await this.getProfile();
            await this.getGlobalConfiguration();
            await this.getUserNotifications();

            setInterval(() => {
                this.getUserNotifications();
            }, 60000);

            const links: any = localStorage.getItem('links-' + this.currentUser._id);

            if (links && typeof links === "string" && links[0] === "[") {
                await this.setLinks(JSON.parse(links));
            }

            if (this.currentUser?.agencyId) {
                await this.setAgency(await this.loadAgency(this.currentUser.agencyId));
            }

            if (this.isAgency) {
                await this.setCollaborators(await this.getAgencyCollaborators());
                await this.setSelectedCollaborators(['all' || this.currentUser._id]);

                if (this.currentUser?.agencyId) {
                    await this.setAgency(await this.loadAgency(this.currentUser.agencyId));
                }

                const started = localStorage.getItem('started');

                if (this.currentAgency) {
                    if (this.currentAgency.subscriptionType === "starter") {
                        if (!started) {
                            await this.$router.push({name: ROUTES.APP.AGENCYPROFILE});
                            localStorage.setItem('started', "1");
                        } else {
                            if (this.$route.name === ROUTES.APP._ROOT) {
                                if (this.$route.name !== ROUTES.APP.HOME) {
                                    await this.$router.push({name: ROUTES.APP.HOME});
                                }
                            }
                        }
                    } else {
                        if (this.currentAgency.subscriptionType === "full") {
                            if (this.$route.name === ROUTES.APP._ROOT) {
                                if (this.$route.name !== ROUTES.APP.HOME) {
                                    await this.$router.push({name: ROUTES.APP.HOME});
                                }
                            }
                        }

                        if (this.currentAgency.subscriptionType === "intermediate") {
                            if (this.$route.name === ROUTES.APP._ROOT) {
                                if (this.$route.name !== ROUTES.APP.HOME) {
                                    await this.$router.push({name: ROUTES.APP.HOME});
                                }
                            }
                        }
                    }
                }
            }

            if (this.isCompany) {
                if (this.currentUser?.companyId) {
                    await this.setCompany(await this.loadCompany(this.currentUser.companyId));
                }
            }

            this.checkRoles(reloading);
        } catch (e) {
            console.log(e);
            await this.auth.logout();
        }
        this.loading = false;
    }

    beforeMount() {
        if (this.menuClosed) {
            this.toggleMenuClose(true)
        }
        this.init();
    }
}
